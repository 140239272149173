import React, { useContext } from "react";
import Context from "./../store/Context";
import useSWR from "swr";
import useForm from "../utils/form";
import { Loader } from "semantic-ui-react";

const Contacto = () => {
	const context = useContext(Context);
	const [data, setValues] = useForm({
		apellido: "",
		email: "",
		mensaje: ""
	});
	const { data: reparticion } = useSWR(() => parseInt(context.state.reparticion, 10) > 0 ?  `reparticion/${context.state.reparticion}`: null);

	const handleFormSubmit = (event) => {
		event.preventDefault();
		let datos = {
			apellido: data.apellido,
			email: data.email,
			mensaje: data.mensaje,
			to: reparticion.email
		};
		console.log(datos);
	};

	if(!reparticion) {
		return <Loader size="huge" />
	}

	return(
		<section id="contact">
			<div className="social">
				<a href={reparticion.facebook} target="_blank" rel="noopener noreferrer">
					<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path></svg>
				</a>
				<a href="https://www.villamercedes.gov.ar" target="_blank" rel="noopener noreferrer">
					<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path></svg>
				</a>
				<a href={`tel:${reparticion.telefono}`} className="link-contacto">
					<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
				</a>
			</div>
			<div className="contact-box">
				<div className="c-heading">
					<h1>Contactanos</h1>
					<p>Llamanos o envianos un correo</p>
				</div>
				<div className="c-inputs">
					<form onSubmit={handleFormSubmit} method="post" autoComplete="off">
						<input type="text" name="apellido" id="apellido" value={data.apellido} onChange={setValues} placeholder="Apellido y Nombre"/>	
						<input type="email" name="email" id="email" value={data.email} onChange={setValues} placeholder="ejemplo@gmail.com"/>
						<textarea name="mensaje" id="mensaje" value={data.mensaje} onChange={setValues} placeholder="Tu mensaje"></textarea>
						<button>Enviar</button>
					</form>
				</div>
			</div>
			<div className="map">
				{
					parseInt(context.state.reparticion, 10) === 1 ?
					<iframe title="Municipio" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.3257637294105!2d-65.46382238483872!3d-33.6746280807103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d16a8821053801%3A0x7dc5e49b66b9f812!2sMunicipalidad%20de%20Villa%20Mercedes!5e0!3m2!1ses!2sar!4v1606615261021!5m2!1ses!2sar" width="600" height="450" frameBorder="0" style={{border: "0"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
					:
					<iframe title="Obras Sanitarias" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13279.72016675127!2d-65.4672819!3d-33.6848756!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4029e0433b872d57!2sObras%20Sanitarias%20Mercedes!5e0!3m2!1ses-419!2sar!4v1606698808908!5m2!1ses-419!2sar" width="600" height="450" frameBorder="0" style={{border: "0"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
				}
			</div>
		</section>
	);
};

export default Contacto;