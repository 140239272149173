import { useState } from "react";

const useForm = (defaultValue) => {
	const [data, setData] = useState(defaultValue);
	const setValues = (event) => {
		let value = event.target.value;
		let field = event.target.name;
		setData({
			...data,
			[field]: value
		});
	};
	return [data, setValues, setData];
};

export default useForm;