import React, { useContext } from "react";
import Context from "./../../store/Context";
import useSWR from "swr";
//import { SET_TABLA } from "./../store/constants";
import { SET_SETTING } from "./../../store/constants";
import { Loader, Grid, Header, Card, Icon } from "semantic-ui-react";

const Choice = ({data}) => {
	const context = useContext(Context);

	const setTabla = () => {
		//context.dispatch({ type: SET_TABLA, payload: data.tabla });
		context.dispatch({ type: SET_SETTING , payload: parseInt(data.id, 10) });
	};

	return(
		<Grid.Column mobile={16} tablet={8} computer={4} textAlign="center">
			<Card style={{margin: "0 auto 1em auto"}} color={data.color}>
				<div style={{padding: "1em", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}} onClick={setTabla}>
					<Icon name={data.icono} size="huge" color={data.color} />
				</div>
				<Card.Content style={{cursor: "pointer"}} onClick={setTabla}>
					<Card.Header>{data.descripcion}</Card.Header>
					<Card.Meta>
						{
							parseInt(data.id, 10) === 11 ?
							<div className="date">Martes y Jueves</div>
							:
							<div className="date">Lunes a Viernes</div>
						}
						<div className="date">Desde las {data.desde_hora} hs.</div>
						<div className="date">Hasta las {data.hasta_hora} hs.</div>
					</Card.Meta>
					<Card.Description>
						<p>Se atiende en {data.domicilio}.</p>
					</Card.Description>
				</Card.Content>
				<Card.Content extra>
					{
						data.telefono === "---" ?
						<a href="mailto:municipalidad@villamercedes.gov.ar?Subject=Consulta" style={{fontSize: ".8rem"}}><Icon name="mail" /> municipalidad@villamercedes.gov.ar</a>
						:
						<a href={`tel:+${data.telefono}`}><Icon name="phone" /> {data.telefono} {data.interno !== "NO" ? `(Int. ${data.interno})` : ""}</a>
					}
				</Card.Content>
			</Card>
		</Grid.Column>
	);
};


const Choices = ({idItem}) => {
	const {data: choices} = useSWR(`configs/reparticion/item/${idItem}`);

	if(!choices) {
		return <Loader active inline="centered" />
	}

	return(
		<>
			<Header size="small" style={{marginBottom: "1em"}}>Para que área necesitas un turno?</Header>
			<Grid stackable centered stretched textAlign="center">
				{
					choices.map((choice) => {
						return <Choice key={`choice-${choice.id}`} data={choice} />
					})
				}
			</Grid>
		</>
	);
};

export default Choices;