import React, { useContext, useState } from "react";
import Context from "./../../../store/Context";
import { SET_UPDATES } from "./../../../store/constants";
import swal from "@sweetalert/with-react";
import api from "./../../../utils/api";
import { Message, Loader } from "semantic-ui-react";

const DetalleTurno = ({data, deleteable}) => {
	const context = useContext(Context);
	const [eliminando, setEliminando] = useState(false);

	if(parseInt(data.turnos, 10) === 0) {
		return <Message color="brown">No hay turnos.</Message>
	}

	const askEliminarTurno = (id) => {
		swal({
			title: "Está seguro?",
			text: "Una vez eliminado no podrá recuperar el turno!",
			icon: "warning",
			//buttons: true,
			dangerMode: true,
			buttons: ["No!", "Si!"],
			content: (
				<div>
					<h1>#{id.padStart(5,"00000")}</h1>
				</div>
			)
		}).then((willDelete) => {
			if(willDelete) {
				setEliminando(true);
				api.delete(`turno/${id}`).then(() => {
					context.dispatch({ type: SET_UPDATES });
				});
			}
		});
	};

	if(eliminando) {
		return <div className="DetalleTurno"><Loader size="huge" /></div>
	}

	return(
		<div className="DetalleTurno">
			{
				data.map((data) => {
					if(deleteable) {
						return (
							<Message
								key={data.id}
								onDismiss={() => askEliminarTurno(data.id)}
								color={data.color}
								icon={data.icono}
								header={`Turno #${data.id.padStart(5,"00000")} - ${data.descripcion}`}
								content={`Tenés un turno para el día ${data.dia}, ${data.fecha.split("-").reverse().join("/")}, a las ${data.hora} hs.`}
							/>
						)
					}
					return (
						<Message
							key={data.id}
							color={data.color}
							icon={data.icono}
							header={`Turno #${data.id.padStart(5,"00000")} - ${data.descripcion}`}
							content={`El turno se te había otorgado para el día ${data.dia}, ${data.fecha.split("-").reverse().join("/")}, a las ${data.hora} hs.`}
						/>
					)
				})
			}
		</div>
	);
};

export default DetalleTurno;