import React, { useState, useRef, useEffect, useContext } from "react";
import Context from "./../../store/Context";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import swal from "@sweetalert/with-react";
import axios from "axios";
import { Loader, Segment, Grid, Header, List, Message, Form, Radio, Checkbox } from "semantic-ui-react";
//import HeaderBack from "./../Headers/HeaderBack";

const useForm = (defaultValue) => { //Ver si se puede reemplazar este por el que está en utils/form.js
	const [data, setData] = useState(defaultValue);

	const handleDataChange = (event) => {
		let field = event.target.name;
		let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		setData((prev) => ({...prev, [field]: value}));
	};

	const setField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	};

	return [data, handleDataChange, setField];
};

const opciones = [
	{ key: "o-0", text: "", value: 0 },
	{ key: "o-1", text: "Débito Automático y Eco Boleta", value: 1 },
	{ key: "o-2", text: "Solo al Débito Automático", value: 2 },
	{ key: "o-3", text: "Solo a la Eco Boleta", value: 3 }
];

const Debito = () => {
	const context = useContext(Context);
	const history = useHistory();
	const dniFileRef = useRef(null);
	const cbuFileRef = useRef(null);
	const [updating, setUpdating] = useState(false);
	const { data: bancos } = useSWR("bancos");
	const [bancosOpt, setBancosOpt] = useState([]);
	const { data: tipocuentas } = useSWR("tiposcuenta");
	const [tiposCuentaOpt, setTiposCuentaOpt] = useState([]);
	const { data: adhesiones } = useSWR(`adhesiones/reparticion/${context.state.reparticion}`);
	const { data: reparticion } = useSWR(`reparticion/${context.state.reparticion}`);
	const [leyendaDescuento, setLeyendaDescuento] = useState("");
	const [data, handleDataChange, setField] = useForm({
		idopcion: 0,
		apellido: "",
		nombre: "",
		telefono: "",
		email: "",
		idreparticion: 0,
		nrofincaconvenio: "",
		idadhiere: "1",
		idtipobanco: 1,
		idtipocuenta: 1,
		declaracion: false
	});

	const [txtFiles, setTxtFiles] = useState({
		dniFile: "",
		cbuFile: ""
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		if(bancos) {
			let opciones = bancos.map((banco) => {
				return { key: `b-${banco.id}`, text: banco.nombre, value: banco.id }
			});
			setBancosOpt(opciones);
			setField("idtipobanco", opciones[0].value);
		}
		if(tipocuentas) {
			let opciones = tipocuentas.map((tipo) => {
				return { key: `tc-${tipo.id}`, text: tipo.descripcion, value: tipo.id }
			});
			setTiposCuentaOpt(opciones);
			setField("idtipocuenta", opciones[0].value);
		}
		if(adhesiones) {
			let leyenda = "";
			adhesiones.forEach((adhesion) => {
				if(parseInt(adhesion.porcentaje, 10) > 0) {
					if(leyenda.length > 0) {
						leyenda += ", ";
					}
					leyenda += `-${adhesion.porcentaje}% - ${adhesion.descripcion}`;
				}
			});
			setLeyendaDescuento(leyenda);
			setField("idadhiere", adhesiones[0].id);
		}
		if(reparticion) {
			setField("idreparticion", reparticion.id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adhesiones, bancos, reparticion, tipocuentas]);

	const handleFileChange = (event) => {
		if(event.target.files[0].size > 2500000) {
			swal(
				<div>
					<h1>Oops!</h1>
					<p>Ocurrió un error.</p>
					<p>El archivo es demasiado grande, no debe superar los 2,5 MB.</p>
				</div>
				,
				{
					icon: "error"
				}
			);
			event.target.value = "";
		} else {
			let archivo = event.target.value.split("\\");
			if(archivo && (archivo.length > 0)) {
				setTxtFiles({...txtFiles, [event.target.name]: archivo[archivo.length - 1]});
			}
		}
	};

	const handleSelectChange = (e, { name, value }) => {
		setField(name, value);
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		if(!updating) {
			if(data.declaracion) {
				let formData = new FormData();
				formData.append("idopcion", data.idopcion);
				formData.append("apellido", data.apellido);
				formData.append("nombre", data.nombre);
				formData.append("telefono", data.telefono);
				formData.append("email", data.email);
				formData.append("idreparticion", data.idreparticion);
				formData.append("nrofincaconvenio", data.nrofincaconvenio);
				formData.append("idadhiere", data.idadhiere);
				formData.append("idtipobanco", data.idtipobanco);
				formData.append("idtipocuenta", data.idtipocuenta);
				formData.append("declaracion", data.declaracion ? "1" : "0");
				formData.append("dniFile", dniFileRef.current.files[0]);
				if(parseInt(data.idopcion, 10) !== 3) { //Solo Eco Boleta
					formData.append("cbuFile", cbuFileRef.current.files[0]);
				}
				setUpdating(true);
				axios({
					method: "post",
					url: `${process.env.REACT_APP_API_PATH}debito`,
					data: formData,
					headers: {"Content-Type": "multipart/form-data" }
				})
				.then(() => {
					swal(
						<div>
							<h1>Ok!</h1>
							<p>Muchas gracias, tu solicitud ha sido cargada.</p>
							<p>Un equipo técnico la evaluará y se comunicará contigo en caso de ser necesario.</p>
						</div>
						,
						{
							icon: "success"
						}
					).then(() => {
						//Redireccionar al Home
						history.push("/");
					});
				})
				.catch((error) => {
					if(error.response) {
						swal(
							<div>
								<h1>Oops!</h1>
								<p>Ocurrió un error.</p>
								<ul>
									{
										error.response.data.errors.map((error, index) => {
											return <li key={`e-${index}`}>{error}</li>
										})
									}
								</ul>
							</div>
							,
							{
								icon: "error"
							}
						);
					} else {
						swal(
							<div>
								<h1>Oops!</h1>        
								<p>Ocurrió un error.</p>
								<p>{error.message}</p>
							</div>
							,
							{
								icon: "error"
							}
						);
					}
				}).then(() => {
					setUpdating(false);
				});
			} else {
				swal(
					<div>
						<h1>Oops!</h1>
						<p>Ocurrió un error.</p>
						<p>Se deben aceptar los términos y condiciones del punto D (Declaración Jurada).</p>
					</div>
					,
					{
						icon: "error"
					}
				);
			}
		}
	};

	if(!bancos || !tipocuentas || !adhesiones || !reparticion) {
		return <Loader size="huge" />
	}

	const reparticionesOpt = [
		{ key: "r-1", text: "Municipalidad de Villa Mercedes", value: "1" },
		{ key: "r-2", text: "Obras Sanitarias Mercedes", value: "2" }
	];

	return(
		<div>
			<Segment style={{marginBottom: "1em"}}>
				<Grid>
					<Form onSubmit={handleFormSubmit} loading={updating} autoComplete="off">
						<Grid.Row>
							<Grid.Column>
								<Header as="h3">ADHESIÓN A DÉBITO AUTOMÁTICO y ECO BOLETA</Header>
								<Message info style={{maxWidth: "none"}}>
									<Message.Header>#QuedateEnCasa</Message.Header>
									<p>La adhesión al Débito Automático y Eco Boleta no solo te proporciona comodidad, ahorro de tiempo y dinero ({leyendaDescuento}, siendo el  mínimo de este descuento la suma de $ 50), sino que además ayuda a preservar el medio ambiente.</p>
								</Message>
								<Form.Select label="Seleccione a que servicio/s desea adherirse" fluid options={opciones} placeholder="" name="idopcion" id="idopcion" value={data.idopcion} onChange={handleSelectChange} />
							</Grid.Column>
						</Grid.Row>
						{
							parseInt(data.idopcion, 10) === 0 ?
							null
							:
							<>
								<Grid.Row style={{marginTop: "1em"}}>
									<Grid.Column>
										<Header as="h4">Adherirte es muy fácil, solo vas a necesitar:</Header>
										<List>
											{
												parseInt(data.idopcion, 10) === 3 ?
												null
												:
												<List.Item>
													<List.Icon name="check" />
													<List.Content>Comprobante (en formato digital) del número de CBU (Clave Bancaria Uniforme - la podés obtener On-Line ingresando al portal web de tu entidad bancaria), de la cuenta de la cual querés que se debiten los pagos.</List.Content>
												</List.Item>
											}
											<List.Item>
												<List.Icon name="check" />
												<List.Content>Copia (en formato digital) del DNI del Suscriptor.</List.Content>
											</List.Item>
										</List>
										<Header as="h4">Tenés tres opciones diferentes para poder adherirte:</Header>
										<List>
											<List.Item>
												<List.Icon name="check" />
												<List.Content>Presentandote, con la documentación requerida, de {reparticion.horario} en nuestras oficinas ({reparticion.domicilio}), sacando el turno correspondiente de ser necesario.</List.Content>
											</List.Item>
											<List.Item>
												<List.Icon name="check" />
												<List.Content>Descargando <a href={`${process.env.REACT_APP_API_PATH}uploads/${reparticion.formulario}`} target="_blank" rel="noopener noreferrer">este</a> formulario, lo imprimís, lo llenas, lo firmas, lo escaneas y lo enviás (junto con el comprobante de CBU y tu copia del DNI) a la dirección de correo electrónica: <a href={`mailto:${reparticion.email}`}>{reparticion.email}</a>.</List.Content>
											</List.Item>
											<List.Item>
												<List.Icon name="check" />
												<List.Content>Rellenando el formulario que te presentamos a continuación:</List.Content>
											</List.Item>
										</List>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row style={{marginTop: "1em"}}>
									<Grid.Column>
										<Header as="h5">A - Datos del Suscriptor</Header>
										<Form.Group widths="equal">
											<Form.Input label="Apellido" name="apellido" id="apellido" value={data.apellido} onChange={handleDataChange} maxLength={25} required />
											<Form.Input label="Nombre" name="nombre" id="nombre" value={data.nombre} onChange={handleDataChange} maxLength={25} required />
										</Form.Group>
										<Form.Group widths="equal">
											<div className="customField">
												<label htmlFor="dniFile">Copia de DNI</label>
												<input ref={dniFileRef} type="file" id="dniFile" name="dniFile" accept=".jpg, .jpeg, .png, .doc, .docx, .pdf" onChange={handleFileChange} required />
											</div>
										</Form.Group>
										<Form.Group widths="equal">
											<Form.Input label="Teléfono" name="telefono" id="telefono" value={data.telefono} onChange={handleDataChange} placeholder="Ej: 2657336528" maxLength={20} required />
											<Form.Input label="Email" type="email" name="email" id="email" value={data.email} onChange={handleDataChange} placeholder="Ej: mimail@server.com" maxLength={150} required />
										</Form.Group>
										<Header as="h5">B - Datos del servicio a adherir</Header>
										<Form.Group widths="equal">
											<Form.Select label="Organismo" fluid options={reparticionesOpt} placeholder="Seleccione la repartición" name="idreparticion" id="idreparticion" value={data.idreparticion} onChange={handleSelectChange} disabled />
											<Form.Input label="Nro. Finca/Convenio" name="nrofincaconvenio" id="nrofincaconvenio" value={data.nrofincaconvenio} onChange={handleDataChange} maxLength={25} required />
										</Form.Group>
										<Form.Group widths="equal">
											{
												adhesiones.map((adhesion) => {
													return(
														<Form.Field key={`ad-${adhesion.id}`}>
															<Radio
																label={parseInt(adhesion.porcentaje, 10) > 0 ? `${adhesion.descripcion} (-${adhesion.porcentaje}%)` : adhesion.descripcion}
																name="idadhiere"
																id={`idadhiere${adhesion.id}`}
																value={adhesion.id}
																checked={data.idadhiere === adhesion.id}
																onChange={handleDataChange}
															/>
														</Form.Field>
													)
												})
											}
										</Form.Group>
										{
											parseInt(data.idopcion, 10) === 3 ?
											null
											:
											<>
												<Header as="h5">C - Datos de la cuenta a debitar</Header>
												<Form.Group widths="equal">
													<Form.Select label="Banco" fluid options={bancosOpt} placeholder="Seleccione el banco" name="idtipobanco" id="idtipobanco" value={data.idtipobanco} onChange={handleSelectChange} />
													<Form.Select label="Tipo Cuenta" fluid options={tiposCuentaOpt} placeholder="Seleccione el tipo de cuenta" name="idtipocuenta" id="idtipocuenta" value={data.idtipocuenta} onChange={handleSelectChange} />
												</Form.Group>
												<Form.Group widths="equal">
													<div className="customField">
														<label htmlFor="cbuFile">Copia de CBU</label>
														<input ref={cbuFileRef} type="file" id="cbuFile" name="cbuFile" accept=".jpg, .jpeg, .png, .doc, .docx, .pdf" onChange={handleFileChange} required />
													</div>
												</Form.Group>
											</>
										}
										<Message success={data.declaracion} warning={!data.declaracion} style={{display: "block"}}>
											<Message.Header>Declaración Jurada!</Message.Header>
											<p>Por medio de la presente autorizo a realizar la operatoria de débito directo correspondiente al Sistema Nacional de Pagos, reglamentado por el Banco Central de la República Argentina en su comunicación A 2559 y modificatorias, en la cuenta cuyos datos se consignan en el punto C.</p>
											{
												parseInt(data.idopcion, 10) === 2 ?
												null
												:
												<p>A partir de la adhesión se enviará a la direeción de correo electrónica suministrada un resúmen del estado de sus cuentas (No se envía la boleta a su domicilio).</p>
											}
											<p>La información consignada precedentemente reviste carácter de <strong>Declaración Jurada</strong>. Su omisión o falsedad procederá al rechazo de la inscripción o la exclusión del registro, sin perjuicio de las sanciones que le pudiera corresponder.</p>
											<Checkbox label="Soy conciente de que los datos que expuse tiene carácter de Declaración Jurada." id="declaracion" name="declaracion" checked={data.declaracion} onChange={handleDataChange} />
										</Message>
										<div style={{display: "flex", justifyContent: "flex-end"}}>
											<Form.Button primary>Enviar Solicitud</Form.Button>
										</div>
									</Grid.Column>
								</Grid.Row>
							</>
						}
					</Form>
				</Grid>
			</Segment>
		</div>
	);
};

export default Debito;