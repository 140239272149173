import React, { useContext, useEffect } from "react";
import Context from "./../../../store/Context";
import { SET_SETTING } from "./../../../store/constants";
import useSWR from "swr";
import { Loader, Segment, Icon } from "semantic-ui-react";

const WhereIam = () => {
	const context = useContext(Context);
	const {data: link} = useSWR(() => parseInt(context.state.idsetting, 10) > 0 ? `configs/setting/${context.state.idsetting}` : null);

	useEffect(() => {
		window.scrollTo(0, 0); //Ver
	}, []);

	const resetTable = () => {
		context.dispatch({ type: SET_SETTING, payload: 0 });
	};

	if(!link) {
		return <Loader active inline="centered" />
	}

	return(
		<Segment inverted color={link.color}>
			<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
				<div><Icon name={link.icono} /> {link.descripcion}</div>
				<Icon name="close" style={{cursor: "pointer"}} onClick={resetTable} />
			</div>
		</Segment>
	);
};

export default WhereIam;