import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
/*
<h1>Noticias</h1>
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis corrupti ullam illo numquam atque! Vel, natus repellendus. Praesentium maiores quaerat cum aspernatur quia ducimus, ullam minus veritatis, provident, adipisci assumenda.</p>
*/

//import debito from "./../imgs/debito.jpeg";
import prorroga from "./../imgs/prorroga.jpg";
import mp1 from "./../imgs/mp1.jpeg";
import mp2 from "./../imgs/mp2.jpeg";

//Nuevos
import PlanActivar from "./../imgs/PlanActivar.jpg";
import PlanPagos from "./../imgs/PlanPagos.jpg";
import PlanPagos2 from "./../imgs/PlanPagos2.jpg";
import BeneficiosComerciales from "./../imgs/BeneficiosComerciales.jpg";
//import RegistroContribuyente from "./../imgs/RegistroContribuyente.jpg";

import imglink from "./../imgs/portalweb.jpeg";


const imagenes = [
	//Nuevos
	//{ id: 1, imagen: RegistroContribuyente, leyenda: "Contribuyente Cumplidor" },
	{ id: 2, imagen: PlanActivar, leyenda: "Plan Activar Comercio" },
	{ id: 3, imagen: PlanPagos, leyenda: "Plan de Pagos" },
	{ id: 4, imagen: PlanPagos2, leyenda: "Débito Automático" },
	{ id: 5, imagen: BeneficiosComerciales, leyenda: "Beneficio a Comerciantes" },
	//Viejos
	//{ id: 1, imagen: debito, leyenda: "Débito Automático" }, //Reemplazado por PlanPagos2
	{ id: 6, imagen: prorroga, leyenda: "Plan Tasa 0" },
	{ id: 7, imagen: mp1, leyenda: "Mercado Pago" },
	{ id: 8, imagen: mp2, leyenda: "Mercado Pago" }
];


/*
<div className="video">
	<video controls autoPlay muted loop>
		<source src={`${process.env.REACT_APP_API_PATH}uploads/tasa0.mp4`} type="video/mp4"/>
		Your browser does not support the video tag.
	</video>
</div>
*/

const Noticias = () => {
	return(
		<section id="noticias">
			<div className="img-link">
				<a href="http://190.107.120.16:8080/apex/f?p=104:LOGIN" target="blank">
					<img src={imglink} alt="Portal Web" />
				</a>
			</div>
			<div className="noticias-carousel">
				<Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} stopOnHover={true} emulateTouch={true} interval={8000} transitionTime={450}>
					{
						imagenes.map((imagen) => {
							return(
								<div key={`img-${imagen.id}`}>
									<img src={imagen.imagen} alt="Img" />
									<p className="legend">{imagen.leyenda}</p>
								</div>
							)
						})
					}
				</Carousel>
			</div>
		</section>
	);
};

export default Noticias;