import React, { useReducer } from "react";
import Context from "./Context";
import reducer from "./reducer";
import { defaultValues } from "./constants";

const DataContext = (props) => {
	const [state, dispatch] = useReducer(reducer, defaultValues);
	return(
		<Context.Provider value={{state, dispatch}}>
			{ props.children }
		</Context.Provider>
	);
};

export default DataContext;