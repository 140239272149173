import React, { useContext } from "react";
import Context from "./../../../store/Context";
import { RESET_USER_WEB } from "./../../../store/constants";
import { Segment, Icon } from "semantic-ui-react";

const UserData = () => {
	const context = useContext(Context);

	const resetUser = () => {
		context.dispatch({ type: RESET_USER_WEB });
	};
	
	return(
		<Segment inverted color="black">
			<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
				<div><Icon name="user" /> {context.state.user_web.data.nombre} ({context.state.user_web.data.dni})</div>
				<Icon name="close" style={{cursor: "pointer"}} onClick={resetUser} />
			</div>
		</Segment>
		
	);
};

export default UserData;