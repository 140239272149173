import React from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import dibujo from "./../imgs/family.png";

const Contributor = () => {
	return(
		<>
			<div className="Contributor">
				<div className="contributor-wrapper">
					<div className="body">
						<div className="title">
							<h4>Registro Integral OnLine del</h4>
							<h3>Contribuyente Cumplidor</h3>
						</div>
						<p className="leyenda">Registra tus Tasas Municipales y de Obras Sanitarias Mercedes para obtener 20% de descuento de premio al cumplimiento y 20% de bonificación en trámites seleccionados.</p>
						<img className="imagen" src={dibujo} alt="Img"/>
						<div className="contri-btn">
							<Link to="/contribuyente">
								<Button primary>Registrate</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="divisor"></div>
		</>
	);
};

export default Contributor;