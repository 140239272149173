import React, { useState, useContext } from "react";
import Context from "./../../../store/Context";
import { SET_USER_WEB } from "./../../../store/constants";
import swal from "sweetalert";
import api from "./../../../utils/api";
import { Form } from "semantic-ui-react";

const FormDNI = () => {
	const context = useContext(Context);
	const [loading, setLoading] = useState(false);
	const [dni, setDni] = useState("");

	const handleFormSubmit = () => {
		if(dni.length < 5) {
			swal("Error!", "El DNI debe contener al menos 5 caracteres!", "error");
			return;
		}
		let getData = async () => {
			setLoading(true);
			let data = await api.get(`usuario/dni/${dni}`);
			if(data.data) {
				context.dispatch({ type: SET_USER_WEB, payload: {
					buscado: true,
					encontrado: true,
					data: data.data
				} });
				setLoading(false);
			} else {
				context.dispatch({ type: SET_USER_WEB, payload: {
					buscado: true,
					encontrado: false,
					data: {
						id: 0,
						dni,
						espar: "0",
						apellido: "",
						nombre: "",
						telefono: "",
						email: ""
					}
				} });
				setLoading(false);
			}
		};
		getData();
	}

	return(
		<Form loading={loading} onSubmit={handleFormSubmit} style={{display: "flex", justifyContent: "center", marginTop: "2em"}}>
			<Form.Group>
    			<Form.Input id="dni" name="dni" value={dni} onChange={ e => setDni(e.target.value) } placeholder="Ingrese su DNI" maxLength={11} />
    			<Form.Button content="Continuar" />
			</Form.Group>
  		</Form>
	);
};

export default FormDNI;