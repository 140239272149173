import React, { useContext, useState, useEffect } from "react";
import Context from "./../../store/Context";
import { Segment } from "semantic-ui-react";

//import HeaderBack from "./../Headers/HeaderBack";


import Items from "./Items";
import Home from "./subcomponents/Home";
import Turnos from "./subcomponents/Turnos";
import New from "./subcomponents/New";

const Cual = () => {
	const context = useContext(Context);
	const [componente, setComponente] = useState(<Home />);
	
	useEffect(() => {
		if(context.state.iditemreparticion !== 0) {
			if(context.state.idsetting !== 0) {
				if(context.state.user_web.buscado) {
					if(context.state.user_web.encontrado) {
						setComponente(<Turnos />);
					} else {
						setComponente(<New />);
					}
				} else {
					setComponente(<Home />);
				}
			} else {
				setComponente(<Items />);
			}
		} else {
			setComponente(<Items />);
		}
	}, [context.state.iditemreparticion, context.state.idsetting, context.state.user_web.buscado, context.state.user_web.encontrado]);

	//<Regresar url="/" reset={false} resetSetting={true} />

	return(
		<>
			<Segment>
				{componente}
			</Segment>
		</>
	); ;
};

export default Cual;