import React, { useContext, useEffect } from "react";
import Context from "./../../../store/Context";
import useSWR, { mutate } from "swr";
import { Loader } from "semantic-ui-react";

import DetalleTurno from "./DetalleTurno";

const Historial = ({data}) => {
	//console.log(data); //ignored no se para que
	const context = useContext(Context);
	const {data: datos} = useSWR(() => parseInt(context.state.user_web.data.id, 10) > 0 ? `usuario/${context.state.user_web.data.id}/historial` : null);

	useEffect(() => {
		mutate(`turnos/usuario/${context.state.user_web.data.id}/disponibles/${context.state.idsetting}`);
	}, [context.state.idsetting, context.state.user_web.data.id, data]);

	if(!datos) {
		return <Loader active inline="centered" />
	}

	return(
		<div className="Historial">
			<h5>Tu historial de Turnos</h5>
			<hr/>
			<DetalleTurno data={datos} deleteable={false} />
		</div>
	);
};

export default Historial;