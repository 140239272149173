export const defaultValues = {
	user_admin: {
		auth: false,
		id: 1,
		name: "Hans",
		token: "123",
		idreparticion: 1,
		esadmin: 1
	},
	reparticion: 0, // debe ser 0
	updates: 0,
	//tabla: null, //muni / carnet / libreta / faltas / transporte / mascotas
	//carnetTipo: 0, //1 renovacion, 2 nuevo (Se utiliza para listar el box)
	iditemreparticion: 0,
	idsetting: 0,
	user_web: {
		buscado: false,
		encontrado: false,
		data: {
			id: 0,
			dni: "",
			espar: 0,
			apellido: "",
			nombre: "",
			telefono: "",
			email: ""
		}
	}
};

/* Administracion del Sistema */
export const SET_USER_ADMIN = "SET_USER_ADMIN";
export const RESET_USER_ADMIN = "RESET_USER_ADMIN";
export const SET_REPARTICION = "SET_REPARTICION";

/* Usuarios Web */
export const RESET_INTERFACE = "RESET_INTERFACE";
export const SET_USER_WEB = "SET_USER_WEB";
export const RESET_USER_WEB = "RESET_USER_WEB";
export const SET_ID_ITEM_REPARTICION = "SET_ID_ITEM_REPARTICION";
export const SET_SETTING = "SET_SETTING";

export const SET_UPDATES = "SET_UPDATES";
/*
export const SET_TABLA = "SET_TABLA";
export const SET_CARNET_TIPO = "SET_CARNET_TIPO";
*/
