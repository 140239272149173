import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";

const change_a_tag = (text) => {
	//Funciona si el parrafo tiene 1 solo link, arreglar!
	let newText = text;
	let inicio = text.indexOf("<a href=");
	let fin = text.indexOf(">", (inicio + 1));
	if(fin !== -1) {
		let cadena = text.substring(inicio, fin + 1);
		if(!cadena.includes("mailto:") && !cadena.includes("tel:") && !cadena.includes("noopener noreferrer")) { //No se modifican ni mail ni teléfono ni tampoco si ya tiene las reglas
			newText = text.substring(0, fin) + " target=\"_blank\" rel=\"noopener noreferrer\"" + text.substring(fin);
		}
	}
	return newText;
};

const List = ({data}) => {
	let { items, style } = data;

	if(style === "ordered") {
		return(
			<ol>
				{
					items.map((item, index) => {
						let text = item;
						if(text.includes("<a href=")) {
							text = change_a_tag(text);
						}
						return <li key={index} dangerouslySetInnerHTML={{__html: text}} />
					})
				}
			</ol>
		);
	} else {
		return(
			<ul>
				{
					items.map((item, index) => {
						let text = item;
						if(text.includes("<a href=")) {
							text = change_a_tag(text);
						}
						return <li key={index} dangerouslySetInnerHTML={{__html: text}} />
					})
				}
			</ul>
		);
	}

}

const Component = ({id, datos}) => {
	const [components, setComponents] = useState([]);

	useEffect(() => {
		if(datos) {
			const GetTypeComponent = (data, key) => {
				let componente = null;
				let text = data.data.text;
				switch (data.type) {
					case "header":
						if(text.includes("<a href=")) {
							text = change_a_tag(text);
						}
						switch (data.data.level) {
							case 1:
								componente = <h1 key={`h-${key}`} dangerouslySetInnerHTML={{__html: text}} />;
								break;
							case 2:
								componente = <h2 key={`h-${key}`} dangerouslySetInnerHTML={{__html: text}} />;
								break;
							case 3:
								componente = <h3 key={`h-${key}`} dangerouslySetInnerHTML={{__html: text}} />;
								break;
							case 4:
								componente = <h4 key={`h-${key}`} dangerouslySetInnerHTML={{__html: text}} />;
								break;
							case 5:
								componente = <h5 key={`h-${key}`} dangerouslySetInnerHTML={{__html: text}} />;
								break;
							case 6:
								componente = <h6 key={`h-${key}`} dangerouslySetInnerHTML={{__html: text}} />;
								break;
							default:
								break;
						}
						break;
					case "paragraph":
						if(text.includes("<a href=")) {
							text = change_a_tag(text);
						}
						componente = <p key={`p-${key}`} dangerouslySetInnerHTML={{__html: text}} />;
						break;
					case "list":
						componente = <List key={`l-${key}`} data={data.data} />;
						break;
					case "embed":
						if(data.data.service === "youtube") {
							componente = <iframe key={`f-${key}`} width={data.data.width} height={data.data.height} src={data.data.embed} title={data.data.caption} />;
						}
						break;
					case "image":
						componente = <img key={`i-${key}`} src={data.data.url} alt={data.data.caption} />
						break;
					default:
						break;
				};
				return componente;
			};
			let bufferComponents = datos.map((d, index) => {
				return GetTypeComponent(d, index);
			});
			setComponents(bufferComponents);
		}
	}, [datos]);

	return(
		<div key={`info-${id}`}>
			{
				components.map((c) => c)
			}
		</div>
	);
};

const Reader = ({info}) => {
	const [data, setData] = useState(null);

	useEffect(() => {
		setData({id: info.id, data: JSON.parse(info.data)});
	}, [info.data, info.id]);

	if(!data) {
		return <Loader size="big" />;
	}

	return(
		<div className="Reader">
			<div className="infos">
				<div className="info">
					<Component key={data.id} id={data.id} datos={data.data.blocks} />
				</div>
			</div>
		</div>
	);
};

export default Reader;