import React from "react";
import WhereIam from "./WhereIam";
import FormDNI from "./FormDNI";
import { Message } from "semantic-ui-react";

const Home = () => {
	return(
		<>
			<WhereIam />
			<FormDNI />
			<Message warning>
				<Message.Header>Importante!</Message.Header>
				<p>Te recordamos que para poder realizar el trámite deberás <strong>presentarte con el documento</strong>!.</p>
			</Message>
		</>
	);
};

export default Home;