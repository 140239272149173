import React, { useContext, useEffect, useReducer } from "react";
import Context from "./../../../store/Context";

import WhereIam from "./WhereIam";
import UserData from "./UserData";
import Opciones from "./Opciones";
import Historial from "./Historial";

const Turnos = () => {
	const context = useContext(Context);
	const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

	useEffect(() => {
		forceUpdate();
	}, [context.state.updates]);
	
	return(
		<>
			<WhereIam />
			<UserData />
			<div className="container">
				<div className="row">
					<div className="col">
						<Opciones data={ignored} />
						<Historial data={ignored} />
					</div>
				</div>
			</div>
		</>
	);
};

export default Turnos;