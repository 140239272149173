import React, { useContext } from "react";
import Context from "./store/Context";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Landing from "./Landing";

import Home from "./Home";
import Wrapper from"./components/Wrapper";

import Contribuyente from "./components/Contribuyente/Contibuyente";
import Cual from "./components/Turnos/Cual";
import Debito from "./components/Debito/Debito";
import Reclamo from "./components/Reclamos/Reclamo";
import Cambio from "./components/Cambios/Cambio";
import Info from "./components/Infos/Info";

//<Router basename="/virtual">

const Layout = () => {
	const context = useContext(Context);

	return(
		<div>
		{
			parseInt(context.state.reparticion, 10) === 0 ?
			<Landing />
			:
			<Router>
				<Switch>
					<Route exact path="/"><Home /></Route>
					<Route exact path="/contribuyente"><Wrapper><Contribuyente /></Wrapper></Route>
					<Route exact path="/turnos"><Wrapper><Cual /></Wrapper></Route>
					<Route exact path="/ecodebito"><Wrapper><Debito /></Wrapper></Route>
					<Route exact path="/reclamo"><Wrapper><Reclamo /></Wrapper></Route>
					<Route exact path="/cambio/domicilio"><Wrapper><Cambio tipo={2} /></Wrapper></Route>
					<Route exact path="/cambio/titularidad"><Wrapper><Cambio tipo={1} /></Wrapper></Route>
					<Route exact path="/guias/info/:id"><Wrapper><Info /></Wrapper></Route>
				</Switch>
			</Router>
		}
		</div>
	);
};

export default Layout;