import React, { useState, useRef, useEffect, useContext } from "react";
import Context from "./../../store/Context";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import swal from "@sweetalert/with-react";
import axios from "axios";
import { Loader, Segment, Grid, Header, List, Message, Form, Checkbox, Label, TextArea } from "semantic-ui-react";
//import HeaderBack from "./../Headers/HeaderBack";

const useForm = (defaultValue) => { //Ver si se puede reemplazar este por el que está en utils/form.js
	const [data, setData] = useState(defaultValue);

	const handleDataChange = (event) => {
		let field = event.target.name;
		let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		setData((prev) => ({...prev, [field]: value}));
	};

	const setField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	};

	return [data, handleDataChange, setField];
};

const Contribuyente = () => {
	const context = useContext(Context);

	const history = useHistory();
	const dniFileFrenteRef = useRef(null);
	const dniFileDorsoRef = useRef(null);
	const extra1Ref = useRef(null);
	const extra2Ref = useRef(null);
	
	const [updating, setUpdating] = useState(false);
	
	const { data: reparticion } = useSWR(`reparticion/${context.state.reparticion}`);
	
	//const { data: tiposnro } = useSWR("contri/tipos/nro");
	//const [optionsNro, setOptionsNro] = useState([]);

	const [cuentasNro, setCuentasNro] = useState([]);

	const [data, handleDataChange, setField] = useForm({
		apellido: "",
		nombre: "",
		dni: "",
		telefono: "",
		domicilio: "",
		email: "",
		observaciones: "",
		idreparticion: "1",
		idtipo: "1",
		nroServicio: "",
		nroComercio: "",
		nroCuenta: "",
		declaracion: false
	});

	const [txtFiles, setTxtFiles] = useState({
		dniFileFrente: "",
		dniFileDorso: ""
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		if(reparticion) {
			setField("idreparticion", context.state.reparticion);
		}
		/*
		if(tiposnro) {
			let opciones = tiposnro.map((tn) => {
				return ({ key: `tn-${tn.id}`, text: tn.descripcion, value: tn.id })
			});
			setOptionsNro(opciones);
			setField("idtipo", context.state.reparticion.toString());
		}
		*/
	// eslint-disable-next-line react-hooks/exhaustive-deps
	//}, [reparticion, tiposnro]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.state.reparticion]);

	const handleFileChange = (event) => {
		if(event.target.files[0].size > 2500000) {
			swal(
				<div>
					<h1>Oops!</h1>
					<p>Ocurrió un error.</p>
					<p>El archivo es demasiado grande, no debe superar los 2,5 MB.</p>
				</div>
				,
				{
					icon: "error"
				}
			);
			event.target.value = "";
		} else {
			let archivo = event.target.value.split("\\");
			if(archivo && (archivo.length > 0)) {
				setTxtFiles({...txtFiles, [event.target.name]: archivo[archivo.length - 1]});
			}
		}
	};

	/*
	const handleSelectChange = (e, { name, value }) => {
		setField(name, value);
	};
	*/

	const handleRemoveNro = (event, data) => {
		event.preventDefault();
		let buscar = data.content;
		buscar = buscar.replace("Finca (TSG) ", "");
		buscar = buscar.replace("Finca (TC) ", "");
		buscar = buscar.replace("Cuenta ", "");
		let newNros = cuentasNro.filter((nro) => nro.nro !== buscar);
		setCuentasNro(newNros);
		setField(event.target.name, "");
	};

	/*
	const handleAddNro = (event) => {
		event.preventDefault();
		var nro = data.nro.trim();
		if(nro.length > 0) {
			let existe = cuentasNro.filter((nro) => nro.nro === nro);
			if(existe.length === 0) {
				setCuentasNro([...cuentasNro, { tipo: data.idtipo, nro }]);
				setField("nro", "");
			}
		}
	};
	*/

	const handleAddNro = (name, tipo) => {
		var nro = data[name].trim();
		if(nro.length > 0) {
			let existe = cuentasNro.filter((nro) => nro.nro === nro);
			if(existe.length === 0) {
				setCuentasNro([...cuentasNro, { tipo: tipo, nro }]);
				setField(name, "");
			}
		}
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		if(!updating) {
			if(!data.declaracion) {
				swal(
					<div>
						<h1>Oops!</h1>
						<p>Ocurrió un error.</p>
						<p>Se deben aceptar los términos y condiciones del punto D (Declaración Jurada).</p>
					</div>
					,
					{
						icon: "error"
					}
				);
				return;
			}
			if(cuentasNro.length === 0) {
				swal(
					<div>
						<h1>Oops!</h1>
						<p>Ocurrió un error.</p>
						<p>Se debe especificar al menos una finca/cuenta.</p>
					</div>
					,
					{
						icon: "error"
					}
				);
				return;
			}
			let formData = new FormData();
			formData.append("apellido", data.apellido);
			formData.append("nombre", data.nombre);
			formData.append("dni", data.dni);
			formData.append("telefono", data.telefono);
			formData.append("domicilio", data.domicilio);
			formData.append("email", data.email);
			formData.append("observaciones", data.observaciones);
			formData.append("idreparticion", data.idreparticion);
			formData.append("declaracion", data.declaracion ? "1" : "0");
			formData.append("dniFileFrente", dniFileFrenteRef.current.files[0]);
			formData.append("dniFileDorso", dniFileDorsoRef.current.files[0]);
			formData.append("extraFileUno", extra1Ref.current.files[0]);
			formData.append("extraFileDos", extra2Ref.current.files[0]);
			//Nros de cuenta
			formData.append("cuentas", JSON.stringify(cuentasNro));
			setUpdating(true);
			axios({
				method: "post",
				url: `${process.env.REACT_APP_API_PATH}contribuyente`,
				data: formData,
				headers: {"Content-Type": "multipart/form-data" }
			})
			.then(() => {
				swal(
					<div>
						<h1>Ok!</h1>
						<p>Muchas gracias, tu solicitud ha sido cargada.</p>
						<p>Un equipo técnico la evaluará y se comunicará contigo en caso de ser necesario.</p>
					</div>
					,
					{
						icon: "success"
					}
				).then(() => {
					//Redireccionar al Home
					history.push("/");
				});
			})
			.catch((error) => {
				if(error.response) {
					swal(
						<div>
							<h1>Oops!</h1>
							<p>Ocurrió un error.</p>
							<ul>
								{
									error.response.data.errors.map((error, index) => {
										return <li key={`e-${index}`}>{error}</li>
									})
								}
							</ul>
						</div>
						,
						{
							icon: "error"
						}
					);
				} else {
					swal(
						<div>
							<h1>Oops!</h1>        
							<p>Ocurrió un error.</p>
							<p>{error.message}</p>
						</div>
						,
						{
							icon: "error"
						}
					);
				}
			}).then(() => {
				setUpdating(false);
			});
		}
	};

	/*
	if(!reparticion || !tiposnro || optionsNro.length === 0) {
		return <Loader size="huge" />
	}
	*/

	if(!reparticion) {
		return <Loader size="huge" />
	}

	return(
		<div>
			<Segment style={{marginBottom: "1em"}}>
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Header as="h3">REGISTRO DEL CONTRIBUYENTE CUMPLIDOR</Header>
							<Message info style={{maxWidth: "none"}}>
								<Message.Header>#QuedateEnCasa</Message.Header>
								<p>Si no adeudas tasas de servicios generales, por contribución sobre la actividad comercial, industrial y de servicios, tasas de obras sanitarias y tribunal de faltas, inscribiéndote obtenés un premio al cumplimiento de 20% de las tasas declardas y 20% de bonificación en trámites municipales que necesites realizar.</p>
							</Message>
							<Header as="h4">Registrarte es muy fácil, solo vas a necesitar:</Header>
							<List>
								<List.Item>
									<List.Icon name="check" />
									<List.Content>Copia (en formato digital) del DNI del Suscriptor.</List.Content>
								</List.Item>
							</List>
							<Header as="h4">Tenés dos opciones diferentes para poder registrarte:</Header>
							<List>
								<List.Item>
									<List.Icon name="check" />
									<List.Content>Presentandote, con la documentación requerida, de {reparticion.horario} en nuestras oficinas ({reparticion.domicilio}), sacando el turno correspondiente de ser necesario.</List.Content>
								</List.Item>
								<List.Item>
									<List.Icon name="check" />
									<List.Content>Rellenando el formulario que te presentamos a continuación:</List.Content>
								</List.Item>
							</List>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form onSubmit={handleFormSubmit} loading={updating} autoComplete="off">
								<Header as="h5">A - Datos del Registro</Header>
								<Form.Group widths="equal">
									<Form.Input label="Apellido" name="apellido" id="apellido" value={data.apellido} onChange={handleDataChange} maxLength={25} required />
									<Form.Input label="Nombre" name="nombre" id="nombre" value={data.nombre} onChange={handleDataChange} maxLength={25} required />
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Input label="Domicilio" name="domicilio" id="domicilio" value={data.domicilio} onChange={handleDataChange} maxLength={100} required />
									<Form.Input label="Nro. DNI" name="dni" id="dni" value={data.dni} onChange={handleDataChange} maxLength={8} required />
								</Form.Group>
								<Form.Group widths="equal">
									<div className="customField">
										<label htmlFor="dniFile">Copia de DNI (Frente)</label>
										<input ref={dniFileFrenteRef} type="file" id="dniFileFrente" name="dniFileFrente" accept=".jpg, .jpeg, .png, .doc, .docx, .pdf" onChange={handleFileChange} required />
									</div>
									<div className="customField">
										<label htmlFor="dniFile">Copia de DNI (Dorso)</label>
										<input ref={dniFileDorsoRef} type="file" id="dniFileDorso" name="dniFileDorso" accept=".jpg, .jpeg, .png, .doc, .docx, .pdf" onChange={handleFileChange} />
									</div>
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Input label="Teléfono" name="telefono" id="telefono" value={data.telefono} onChange={handleDataChange} placeholder="Ej: 2657336528" maxLength={20} required />
									<Form.Input label="Email" type="email" name="email" id="email" value={data.email} onChange={handleDataChange} placeholder="Ej: mimail@server.com" maxLength={150} required />
								</Form.Group>
								<p>Para completar el registro es necesario que nos indiques la o las fincas (Municipalidad tanto de servicio como comerciale) y cuentas (Obras Sanitarias) de las cuales seas el responsable.</p>
								<Form.Group widths="equal">
									<Form.Input label="Mun. Nro. Finca Serv. Generales" type="text" name="nroServicio" id="nroServicio" value={data.nroServicio} onChange={handleDataChange} placeholder="Ej: 230" maxLength={15}
										action={{
											color: "teal",
											icon: "arrow down",
											onClick: (e) => {
												e.preventDefault();
												handleAddNro("nroServicio", "1");
											}
										}}
									/>
									<Form.Input label="Mun. Nro. Finca Comercio" type="text" name="nroComercio" id="nroComercio" value={data.nroComercio} onChange={handleDataChange} placeholder="Ej: 230" maxLength={15}
										action={{
											color: "teal",
											icon: "arrow down",
											onClick: (e) => {
												e.preventDefault();
												handleAddNro("nroComercio", "2");
											}
										}}
									/>
									<Form.Input label="Obras Sanitarias Nro. Cuenta" type="text" name="nroCuenta" id="nroCuenta" value={data.nroCuenta} onChange={handleDataChange} placeholder="Ej: 230" maxLength={15}
										action={{
											color: "teal",
											icon: "arrow down",
											onClick: (e) => {
												e.preventDefault();
												handleAddNro("nroCuenta", "3");
											}
										}}
									/>
								</Form.Group>
								{/*
								<Form.Group widths="equal">
									<Form.Select label="Tipo" fluid options={optionsNro} placeholder="Tipo" name="idtipo" id="idtipo" value={data.idtipo} onChange={handleSelectChange} />
									<Form.Input label={data.idtipo === "1" ? "Nro. Finca" : "Nro. Cuenta"} type="text" name="nro" id="nro" value={data.nro} onChange={handleDataChange} placeholder="Ej: 230" maxLength={15}
										action={{
											color: "teal",
											icon: "arrow down",
											onClick: handleAddNro
										}}
									/>
								</Form.Group>
								*/}
								<div>
									{
										cuentasNro.map((nro, index) => {
											let color = "blue";
											let leyenda = "Cuenta";
											switch (nro.tipo) {
												case "1": //Tasa de Servicios Generales
													color = "green";
													leyenda = "Finca (TSG) ";
													break;
												case "2": //Tasa de Comercio
													color = "red";
													leyenda = "Finca (TC) ";
													break;
												case "3": //Obras Sanitarias
													color = "blue";
													leyenda = "Cuenta ";
													break;
												default: //Obras Sanitarias
													color = "blue";
													leyenda = "";
													break;
											}
											return <Label key={`nro-${index}`} size="large" style={{ marginBottom: ".5em" }} color={color} content={`${leyenda}${nro.nro}`} removeIcon="delete" onRemove={handleRemoveNro} />
										})
									}
								</div>
								<p>Si lo cree necesario adjunte al presente documentación que acredite la titularidad de la/s finca/s y cuenta/s seleccionadas.</p>
								<Form.Group widths="equal">
									<div className="customField">
										<label htmlFor="extra1">Extra (1)</label>
										<input ref={extra1Ref} type="file" id="extra1" name="extra1" accept=".jpg, .jpeg, .png, .doc, .docx, .pdf" onChange={handleFileChange} />
									</div>
									<div className="customField">
										<label htmlFor="extra2">Extra (2)</label>
										<input ref={extra2Ref} type="file" id="extra2" name="extra2" accept=".jpg, .jpeg, .png, .doc, .docx, .pdf" onChange={handleFileChange} />
									</div>
								</Form.Group>
								<Form.Field style={{ marginTop: ".5em" }}>
      								<label>Observaciones</label>
									<TextArea name="observaciones" id="observaciones" value={data.observaciones} onChange={handleDataChange} placeholder="Lo que nos quieras comunicar..." maxLength={500} style={{ minHeight: 100 }} />
									<div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1em" }}>
										<span style={{ fontSize: ".8rem", fontStyle: "italic" }}>{`${500 - data.observaciones.length} caracteres de 500.`}</span>
									</div>
								</Form.Field>
								<Message success={data.declaracion} warning={!data.declaracion} style={{display: "block"}}>
									<Message.Header>Declaración Jurada!</Message.Header>
									<p>La información consignada precedentemente reviste carácter de <strong>Declaración Jurada</strong>. Su omisión o falsedad procederá al rechazo de la inscripción o la exclusión del registro, sin perjuicio de las sanciones que le pudiera corresponder.</p>
									<Checkbox label="Soy conciente de que los datos que expuse tiene carácter de Declaración Jurada." id="declaracion" name="declaracion" checked={data.declaracion} onChange={handleDataChange} />
								</Message>
								<div style={{display: "flex", justifyContent: "flex-end"}}>
									<Form.Button primary>Enviar Solicitud</Form.Button>
								</div>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</div>
	);
};

export default Contribuyente;