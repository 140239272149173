import React, { useState, useEffect, useContext } from "react";
import Context from "./../../store/Context";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import swal from "@sweetalert/with-react";
import axios from "axios";
import { Loader, Segment, Grid, Header, List, Form, Radio, TextArea } from "semantic-ui-react";
import HeaderBack from "./../Headers/HeaderBack";

const useForm = (defaultValue) => { //Ver si se puede reemplazar este por el que está en utils/form.js
	const [data, setData] = useState(defaultValue);

	const handleDataChange = (event) => {
		let field = event.target.name;
		let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		setData((prev) => ({...prev, [field]: value}));
	};

	const setField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	};

	return [data, handleDataChange, setField];
};

const Reclamo = () => {
	const context = useContext(Context);
	const history = useHistory();

	const [updating, setUpdating] = useState(false);
	const { data: tipocuentas } = useSWR("tiposcuenta");

	const { data: adhesiones } = useSWR(`adhesiones/reparticion/${context.state.reparticion}`);
	const { data: reparticion } = useSWR(`reparticion/${context.state.reparticion}`);

	const [data, handleDataChange, setField] = useForm({
		apellido: "",
		nombre: "",
		dni: "",
		telefono: "",
		email: "",
		idreparticion: 0,
		nrofincaconvenio: "",
		idadhiere: "",
		observaciones: ""
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		if(tipocuentas) {
			let opciones = tipocuentas.map((tipo) => {
				return { key: `tc-${tipo.id}`, text: tipo.descripcion, value: tipo.id }
			});
			setField("idtipocuenta", opciones[0].value);
		}
		if(adhesiones) {
			setField("idadhiere", adhesiones[1].id);
		}
		if(reparticion) {
			setField("idreparticion", reparticion.id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adhesiones, reparticion, tipocuentas]);

	const handleFormSubmit = (event) => {
		event.preventDefault();
		if(!updating) {
			setUpdating(true);
			axios({
				method: "post",
				url: `${process.env.REACT_APP_API_PATH}reclamo`,
				data: JSON.stringify(data),
				headers: {"Content-Type": "application/json" }
			})
			.then(() => {
				swal(
					<div>
						<h1>Ok!</h1>
						<p>Muchas gracias, tu reclamo ha sido cargado.</p>
						<p>Un equipo técnico la evaluará y se comunicará contigo en caso de ser necesario.</p>
					</div>
					,
					{
						icon: "success"
					}
				).then(() => {
					//Redireccionar al Home
					history.push("/home");
				});
			})
			.catch((error) => {
				if(error.response) {
					console.log(error.response);
					swal(
						<div>
							<h1>Oops!</h1>
							<p>Ocurrió un error.</p>
							<ul>
								{
									error.response.data.errors.map((error, index) => {
										return <li key={`e-${index}`}>{error}</li>
									})
								}
							</ul>
						</div>
						,
						{
							icon: "error"
						}
					);
				} else {
					swal(
						<div>
							<h1>Oops!</h1>        
							<p>Ocurrió un error.</p>
							<p>{error.message}</p>
						</div>
						,
						{
							icon: "error"
						}
					);
				}
			}).then(() => {
				setUpdating(false);
			});
		}
	};

	if(!tipocuentas || !adhesiones || !reparticion) {
		return <Loader size="huge" />
	}

	const reparticionesOpt = [
		{ key: "r-1", text: "Municipalidad de Villa Mercedes", value: "1" },
		{ key: "r-2", text: "Obras Sanitarias Mercedes", value: "2" }
	];

	return(
		<div>
			<HeaderBack url="/" reset={false} />
			<Segment style={{marginBottom: "1em"}}>
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Header as="h3">Reclamos de Boletas</Header>
							<Header as="h4">Tenés tres opciones diferentes para poder reclamar tu boleta:</Header>
							<List>
								<List.Item>
									<List.Icon name="check" />
									<List.Content>Presentandote, con la documentación requerida, de {reparticion.horario} en nuestras oficinas ({reparticion.domicilio}), sacando el turno correspondiente de ser necesario.</List.Content>
								</List.Item>
								<List.Item>
									<List.Icon name="check" />
									<List.Content>Enviando a la dirección de correo electrónica: <a href={`mailto:${reparticion.email}`}>{reparticion.email}</a>, toda la infromación referente al reclamo.</List.Content>
								</List.Item>
								<List.Item>
									<List.Icon name="check" />
									<List.Content>Rellenando el formulario que te presentamos a continuación:</List.Content>
								</List.Item>
							</List>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form onSubmit={handleFormSubmit} loading={updating} autoComplete="off">
								<Header as="h5">Datos Requeridos</Header>
								<Form.Group widths="equal">
									<Form.Input label="Apellido" name="apellido" id="apellido" value={data.apellido} onChange={handleDataChange} maxLength={25} required />
									<Form.Input label="Nombre" name="nombre" id="nombre" value={data.nombre} onChange={handleDataChange} maxLength={25} required />
									<Form.Input label="DNI" name="dni" id="dni" value={data.dni} onChange={handleDataChange} maxLength={8} required />
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Input label="Teléfono" name="telefono" id="telefono" value={data.telefono} onChange={handleDataChange} placeholder="Ej: 2657336528" maxLength={20} required />
									<Form.Input label="Email" type="email" name="email" id="email" value={data.email} onChange={handleDataChange} placeholder="Ej: mimail@server.com" maxLength={150} required />
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Select label="Organismo" fluid options={reparticionesOpt} placeholder="Seleccione la repartición" name="idreparticion" id="idreparticion" value={data.idreparticion} onChange={handleDataChange} disabled />
									<Form.Input label="Nro. Finca/Convenio" name="nrofincaconvenio" id="nrofincaconvenio" value={data.nrofincaconvenio} onChange={handleDataChange} maxLength={25} required />
								</Form.Group>
								<Header as="h4">Su reclamos es por la boleta de:</Header>
								<Form.Group widths="equal">
									{
										adhesiones.map((adhesion) => {
											if(adhesion.id === "1") return null;
											return(
												<Form.Field key={`ad-${adhesion.id}`}>
													<Radio
														label={adhesion.descripcion}
														name="idadhiere"
														id={`idadhiere${adhesion.id}`}
														value={adhesion.id}
														checked={data.idadhiere === adhesion.id}
														onChange={handleDataChange}
													/>
												</Form.Field>
											)
										})
									}
								</Form.Group>
								<Form.Group widths="equal">
									<TextArea name="observaciones" id="observaciones" value={data.observaciones} onChange={handleDataChange} placeholder="Contanos la situación." />
								</Form.Group>
								<div style={{display: "flex", justifyContent: "flex-end"}}>
									<Form.Button primary>Enviar</Form.Button>
								</div>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</div>
	);
};

export default Reclamo;