import React, { useContext, useState, useEffect } from "react";
import useSWR from "swr";
import Context from "./../../store/Context";
import { SET_ID_ITEM_REPARTICION } from "./../../store/constants";
import { Accordion, Icon, Message } from "semantic-ui-react";

import Choices from "./Choices";

const Items = () => {
	const context = useContext(Context);
	const {data: items} = useSWR(() => parseInt(context.state.reparticion, 10) > 0 ? `reparticion/${context.state.reparticion}/items` : null);
	const [activeItem, setActiveItem] = useState(-1);

	useEffect(() => {
		if(items) {
			setActiveItem(parseInt(items[0].id, 10));
			context.dispatch({type: SET_ID_ITEM_REPARTICION, payload: parseInt(items[0].id, 10)});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	const handleItemChange = (id) => {
		setActiveItem(parseInt(id, 10));
		context.dispatch({type: SET_ID_ITEM_REPARTICION, payload: parseInt(id, 10)});
	};

	if(!items) {
		return(<h1>Loading...</h1>);
	}

	return(
		<div className="Items">
			<Accordion fluid>
					{
						items.map((it) => {
							return(
								<Message key={`item-${it.id}`} color={it.color}>
									<Accordion.Title active={activeItem === parseInt(it.id, 10)} index={0} onClick={() => handleItemChange(it.id)}>
										<Icon name="dropdown" />
										<Icon name={it.icono} /> {it.descripcion}
									</Accordion.Title>
									<Accordion.Content active={activeItem === parseInt(it.id, 10)}>
										<Choices idItem={it.id} />
									</Accordion.Content>
								</Message>
							)
						})
					}
			</Accordion>
		</div>
	);
};

export default Items;