import React, { useContext, useState } from "react";
import Context from "./../../../store/Context";
import { RESET_USER_WEB, SET_USER_WEB } from "./../../../store/constants";
import swal from "@sweetalert/with-react";
import api from "./../../../utils/api";
import useForm from "./../../../utils/form";
import { Form, Button } from "semantic-ui-react";

const New = () => {
	const context = useContext(Context);
	const [procesando, setProcesando] = useState(false);
	const [data, setData] = useForm({
		dni: context.state.user_web.data.dni,
		apellido: "",
		nombre: "",
		telefono: "",
		email: ""
	});

	const handleRegresar = () => {
		context.dispatch({ type: RESET_USER_WEB });
	}

	const handleFormSubmit = () => {
		setProcesando(true);
		api.post("usuario", data)
		.then((response) => {
			api.get(`usuario/id/${response.data.id}`)
			.then((response) => {
				context.dispatch({ type: SET_USER_WEB, payload: {
					buscado: true,
					encontrado: true,
					data: response.data
				} });
			});
		})
		.catch((error) => {
			if(error.response) {
				swal(
					<div>
						<h1>Oops!</h1>        
						<p>Ocurrió un error.</p>
						<ul>
							{
								error.response.data.errors.map((error, index) => {
									return <li key={`e-${index}`}>{error}</li>
								})
							}
						</ul>
					</div>
					,
					{
						icon: "error"
					}
				);
			} else {
				swal(
					<div>
						<h1>Oops!</h1>        
						<p>Ocurrió un error.</p>
						<p>{error.message}</p>
					</div>
					,
					{
						icon: "error"
					}
				);
			}
			setProcesando(false);
		});
	}

	return(
		<>
			<h3>Bienvenido/a</h3>
			<p>Antes de continuar necesitamos algunos datos tuyos.</p>
			<h4 className="text-info text-right">DNI: <strong>{context.state.user_web.data.dni}</strong></h4>
			<Form onSubmit={handleFormSubmit} loading={procesando}>
				<Form.Group widths="equal">
					<Form.Input label="Nombre" name="nombre" id="nombre" value={data.nombre} onChange={setData} placeholder="Ej: John" maxLength={25} />
					<Form.Input label="Apellido" name="apellido" id="apellido" value={data.apellido} onChange={setData} placeholder="Ej: Doe" maxLength={25} />
				</Form.Group>
				<Form.Group widths="equal">
					<Form.Input label="Tel/Cel." name="telefono" id="telefono" value={data.telefono} onChange={setData} placeholder="Ej: 2657345593" maxLength={25} />
					<Form.Input label="E-Mail" name="email" id="email" value={data.email} onChange={setData} placeholder="Ej: johndoe@server.com" maxLength={150} />
				</Form.Group>
				<div style={{display: "flex", justifyContent: "flex-end"}}>
					<Button.Group>
						<Button onClick={handleRegresar}>Cancelar</Button>
						<Button.Or text="ó" />
						<Button positive onClick={handleFormSubmit}>Conitnuar</Button>
					</Button.Group>
				</div>
			</Form>
		</>
	);
};

/*
return(
	<div className="New container">
		<div className="row">
			<div className="col">
				<h3>Bienvenido/a</h3>
				<p>Antes de continuar necesitamos algunos datos tuyos.</p>
				<h4 className="text-info text-right">DNI: <strong>{context.state.user_web.data.dni}</strong></h4>
				<div className="formulario">
					<div className="form-row">
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label htmlFor="nombre">Nombre/s</label>
								<input type="text" className="form-control" name="nombre" id="nombre" value={data.nombre} onChange={setData} placeholder="Ej: John" maxLength={25} />
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label htmlFor="apellido">Apellido/s</label>
								<input type="text" className="form-control" name="apellido" id="apellido" value={data.apellido} onChange={setData} placeholder="Ej: Doe" maxLength={25} />
							</div>
						</div>
					</div>
					<div className="form-row">
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label htmlFor="telefono">Tel./Cel.</label>
								<input type="text" className="form-control" name="telefono" id="telefono" value={data.telefono} onChange={setData} placeholder="Ej: 2657345593" maxLength={25} />
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label htmlFor="email">E-Mail</label>
								<input type="email" className="form-control" name="email" id="email" value={data.email} onChange={setData} placeholder="Ej: johndoe@server.com" maxLength={150} />
							</div>
						</div>
					</div>
					<div className="form-row">
						<div className="col-12 col-md-12">
							{
								procesando ?
								<div className="lds-ellipsis ml-auto"><div></div><div></div><div></div><div></div></div>
								:
								<div className="d-flex justify-content-between">
									<button type="button" className="btn btn-danger" onClick={handleRegresar}>Regresar</button>
									<button type="button" className="btn btn-primary" onClick={handleFormSubmit}>Continuar</button>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);
*/

export default New;