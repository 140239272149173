import React from "react";
import { Container, Grid  } from "semantic-ui-react";

import HeaderBack from "./Headers/HeaderBack";
import Footer from "./Footer";

const Wrapper = ({backTo, children}) => {
	return(
		<div className="Wrapper">
			<HeaderBack url="/" />
			<Container style={{marginBottom: "4em"}}>
				<Grid.Row>
					<Grid.Column width={16}>
						{children}
					</Grid.Column>
				</Grid.Row>
			</Container>
			<Footer />
		</div>
	);
};

export default Wrapper;