import React, { useState, useRef, useEffect, useContext } from "react";
import Context from "./../../store/Context";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import swal from "@sweetalert/with-react";
import axios from "axios";
import { Container, Loader, Segment, Grid, Header, List, Message, Form, TextArea, Checkbox } from "semantic-ui-react";
import HeaderBack from "./../Headers/HeaderBack"

const useForm = (defaultValue) => { //Ver si se puede reemplazar este por el que está en utils/form.js
	const [data, setData] = useState(defaultValue);

	const handleDataChange = (event) => {
		let field = event.target.name;
		let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		setData((prev) => ({...prev, [field]: value}));
	};

	const setField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	};

	return [data, handleDataChange, setField];
};

const Cambio = ({tipo}) => {
	const context = useContext(Context);
	const history = useHistory();
	const dniFileRef = useRef(null);
	const cbuFileRef = useRef(null);
	const [updating, setUpdating] = useState(false);

	const { data: reparticion } = useSWR(`reparticion/${context.state.reparticion}`);
	
	const [data, handleDataChange, setField] = useForm({
		apellido: "",
		nombre: "",
		domicilio: "",
		telefono: "",
		email: "",
		idreparticion: 0,
		nrofincaconvenio: "",
		observaciones: "",
		declaracion: false,
		idtipocambio: tipo
	});

	const [txtFiles, setTxtFiles] = useState({
		dniFile: "",
		cbuFile: ""
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		if(reparticion) {
			setField("idreparticion", reparticion.id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reparticion]);

	const handleFileChange = (event) => {
		if(event.target.files[0].size > 2500000) {
			swal(
				<div>
					<h1>Oops!</h1>
					<p>Ocurrió un error.</p>
					<p>El archivo es demasiado grande, no debe superar los 2,5 MB.</p>
				</div>
				,
				{
					icon: "error"
				}
			);
			event.target.value = "";
		} else {
			let archivo = event.target.value.split("\\");
			if(archivo && (archivo.length > 0)) {
				setTxtFiles({...txtFiles, [event.target.name]: archivo[archivo.length - 1]});
			}
		}
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		if(!updating) {
			if(data.declaracion) {
				let formData = new FormData();
				formData.append("apellido", data.apellido);
				formData.append("nombre", data.nombre);
				formData.append("domicilio", data.domicilio);
				formData.append("telefono", data.telefono);
				formData.append("email", data.email);
				formData.append("idreparticion", data.idreparticion);
				formData.append("nrofincaconvenio", data.nrofincaconvenio);
				formData.append("declaracion", data.declaracion ? "1" : "0");
				formData.append("observaciones", data.observaciones);
				formData.append("idtipocambio", data.idtipocambio);
				formData.append("dniFile", dniFileRef.current.files[0]);
				formData.append("acreditacionFile", cbuFileRef.current.files[0]);
				setUpdating(true);
				axios({
					method: "post",
					url: `${process.env.REACT_APP_API_PATH}cambio`,
					data: formData,
					headers: {"Content-Type": "multipart/form-data" }
				})
				.then(() => {
					swal(
						<div>
							<h1>Ok!</h1>
							<p>Muchas gracias, tu solicitud ha sido cargada.</p>
							<p>Un equipo técnico la evaluará y se comunicará contigo en caso de ser necesario.</p>
						</div>
						,
						{
							icon: "success"
						}
					).then(() => {
						//Redireccionar al Home
						history.push("/home");
					});
				})
				.catch((error) => {
					if(error.response) {
						swal(
							<div>
								<h1>Oops!</h1>
								<p>Ocurrió un error.</p>
								<ul>
									{
										error.response.data.errors.map((error, index) => {
											return <li key={`e-${index}`}>{error}</li>
										})
									}
								</ul>
							</div>
							,
							{
								icon: "error"
							}
						);
					} else {
						swal(
							<div>
								<h1>Oops!</h1>        
								<p>Ocurrió un error.</p>
								<p>{error.message}</p>
							</div>
							,
							{
								icon: "error"
							}
						);
					}
				}).then(() => {
					setUpdating(false);
				});
			} else {
				swal(
					<div>
						<h1>Oops!</h1>
						<p>Ocurrió un error.</p>
						<p>Se deben aceptar los términos y condiciones (Declaración Jurada).</p>
					</div>
					,
					{
						icon: "error"
					}
				);
			}
		}
	};

	if(!reparticion) {
		return <Loader size="huge" />
	}

	const reparticionesOpt = [
		{ key: "r-1", text: "Municipalidad de Villa Mercedes", value: "1" },
		{ key: "r-2", text: "Obras Sanitarias Mercedes", value: "2" }
	];

	return(
		<Container>
			<HeaderBack url="/" reset={false} />
			<Segment style={{marginBottom: "1em"}}>
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Header as="h3">{tipo === 1 ? "Cambio de Titularidad de Inmueble" : "Cambio de Domcilio Postal"}</Header>
							<Header as="h4">Realizar el trámite es muy fácil, solo vas a necesitar:</Header>
							<List>
								<List.Item>
									<List.Icon name="check" />
									<List.Content>Comprobante (en formato digital) que acredite que sos el titularidad del inmueble.</List.Content>
								</List.Item>
								<List.Item>
									<List.Icon name="check" />
									<List.Content>Comprobante (en formato digital) de tu documento de identidad (frente y dorso).</List.Content>
								</List.Item>
							</List>
							<Header as="h4">Tenés dos opciones para realizar el trámite:</Header>
							<List>
								<List.Item>
									<List.Icon name="check" />
									<List.Content>Presentandote, con la documentación requerida, de {reparticion.horario} en nuestras oficinas ({reparticion.domicilio}), sacando el turno correspondiente de ser necesario.</List.Content>
								</List.Item>
								<List.Item>
									<List.Icon name="check" />
									<List.Content>Rellenando el formulario que te presentamos a continuación:</List.Content>
								</List.Item>
							</List>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Form onSubmit={handleFormSubmit} loading={updating} autoComplete="off">
								<Header as="h5">A - Datos del Solicitante</Header>
								<Form.Group widths="equal">
									<Form.Input label="Apellido" name="apellido" id="apellido" value={data.apellido} onChange={handleDataChange} maxLength={25} required />
									<Form.Input label="Nombre" name="nombre" id="nombre" value={data.nombre} onChange={handleDataChange} maxLength={25} required />
								</Form.Group>
								<Form.Group widths="equal">
									<div className="customField">
										<label htmlFor="cbuFile">Copia de DNI</label>
										<input ref={dniFileRef} type="file" id="dniFile" name="dniFile" accept=".jpg, .jpeg, .png, .doc, .docx, .pdf" onChange={handleFileChange} required />
									</div>
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Input label="Domicilio" name="domicilio" id="domicilio" value={data.domicilio} onChange={handleDataChange} placeholder="Ej: Av. Mitre 1250" maxLength={50} required />
									<Form.Input label="Teléfono" name="telefono" id="telefono" value={data.telefono} onChange={handleDataChange} placeholder="Ej: 2657336528" maxLength={20} required />
									<Form.Input label="Email" type="email" name="email" id="email" value={data.email} onChange={handleDataChange} placeholder="Ej: mimail@server.com" maxLength={150} required />
								</Form.Group>
								<Header as="h5">B - Datos del Inmueble</Header>
								<Form.Group widths="equal">
									<Form.Select label="Organismo" fluid options={reparticionesOpt} placeholder="Seleccione la repartición" name="idreparticion" id="idreparticion" value={data.idreparticion} onChange={handleDataChange} disabled />
									<Form.Input label={reparticion.id === "1" ? "Nro. Finca" : "Nro. Cuenta"} name="nrofincaconvenio" id="nrofincaconvenio" value={data.nrofincaconvenio} onChange={handleDataChange} maxLength={25} required />
								</Form.Group>
								<Form.Group widths="equal">
									<div className="customField">
										<label htmlFor="cbuFile">Documentación que acredite titularidad</label>
										<input ref={cbuFileRef} type="file" id="cbuFile" name="cbuFile" accept=".jpg, .jpeg, .png, .doc, .docx, .pdf" onChange={handleFileChange} required />
									</div>
								</Form.Group>
								<Form.Group widths="equal">
									<div className="customField">
										<label htmlFor="observaciones">Observaciones</label>
										<TextArea name="observaciones" id="observaciones" value={data.observaciones} onChange={handleDataChange} placeholder="Contanos la situación." />
									</div>
								</Form.Group>
								<Header as="h5">C - Declaración Jurada</Header>
								<Message success={data.declaracion} warning={!data.declaracion} style={{display: "block"}}>
									<Message.Header>Declaración Jurada!</Message.Header>
									<p>La información consignada precedentemente reviste carácter de <strong>Declaración Jurada</strong>. Su omisión o falsedad procederá al rechazo de la inscripción o la exclusión del registro, sin perjuicio de las sanciones que le pudiera corresponder.</p>
									<Checkbox label="Soy conciente de que los datos que expuse tiene carácter de Declaración Jurada." id="declaracion" name="declaracion" checked={data.declaracion} onChange={handleDataChange} />
								</Message>
								<div style={{display: "flex", justifyContent: "flex-end"}}>
									<Form.Button primary>Enviar Solicitud</Form.Button>
								</div>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</Container>
	);
};

export default Cambio;