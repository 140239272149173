import React, { useContext } from "react";
import Context from "./../../store/Context";
import { RESET_INTERFACE, SET_SETTING, RESET_USER_WEB } from "./../../store/constants";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";

const Regresar = ({url, reset = false, resetSetting = false, resetUserWeb = false}) => {
	const context = useContext(Context);
	const history = useHistory();

	const handleRegresarClick = () => {
		history.push(url);
		if(reset) {
			context.dispatch({type: RESET_INTERFACE});
		}
		if(resetSetting) {
			context.dispatch({type: SET_SETTING, payload: 0});
		}
		if(resetUserWeb) {
			context.dispatch({type: RESET_USER_WEB});
		}
	};

	return(
		<div style={{margiBottom: "1em"}}>
			<Icon circular link name='arrow alternate circle left' inverted size='big' onClick={handleRegresarClick} />
		</div>
	);
};

export default Regresar;