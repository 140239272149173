import { defaultValues, SET_USER_ADMIN, RESET_INTERFACE, SET_USER_WEB, RESET_USER_WEB, SET_REPARTICION, SET_ID_ITEM_REPARTICION, SET_SETTING, SET_UPDATES } from "./constants";
//import { SET_REPARTICION } from "./constants";


const reducer = (state, action) => {
	switch(action.type) {
		case SET_USER_ADMIN:
			return { ...state, user_admin: { //Esto será a futuro action.payload
				auth: true,
				id: 1,
				name: "Hans",
				token: "0123456789",
				idreparticion: 0,
				esadmin: 1
			}};
		case RESET_INTERFACE: //El usuario web no se resetea si ya puso su DNI
			return {
				...defaultValues,
				user_web: state.user_web
			};
		case SET_USER_WEB:
			return { 
				...state, 
				user_web: action.payload
			};
		case RESET_USER_WEB: //Se resetea también la tabla?
			return {
				...state,
				user_web: {
					buscado: false,
					encontrado: false,
					data: {
						id: "0",
						dni: "",
						espar: "0",
						apellido: "",
						nombre: "",
						telefono: "",
						email: ""
					}
				},
				//tabla: state.tabla,
				idsetting: state.idsetting
			};
		case SET_REPARTICION: 
			return { ...state, reparticion: action.payload };
		case SET_ID_ITEM_REPARTICION:
			return { ...state, iditemreparticion: action.payload };
		case SET_SETTING:
			return { ...state, idsetting: action.payload };
			/*
			case SET_TABLA:
				return { ...state, tabla: action.payload, carnetTipo: 0 }
			case SET_CARNET_TIPO:
				return { ...state, carnetTipo: action.payload }
			/*
			case RESET_STATE:
				//let data = { ...defaultValues };
				//data.tabla = state.tabla;
				//return data;
				return { ...defaultValues };
			*/
		case SET_UPDATES:
			return { ...state, updates: (state.updates + 1) };
		default:
			return state;
	}
};

export default reducer;