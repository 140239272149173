//import React, { useContext, useState, useEffect  } from "react";
import React, { useContext, useEffect, useCallback  } from "react";
import Context from "./../../../store/Context";

import useSWR, { mutate } from "swr";
import { Loader } from "semantic-ui-react";
import SetTurno from "./SetTurno";
import DetalleTurno from "./DetalleTurno";

const Opciones = ({data}) => {
	const context = useContext(Context);
	const {data: datos} = useSWR(() => ((parseInt(context.state.idsetting, 10) > 0) && (parseInt(context.state.user_web.data.id, 10) > 0)) ? `turnos/usuario/${context.state.user_web.data.id}/disponibles/${context.state.idsetting}` : null);

	const forceUpdate = useCallback(() => {
		mutate(`turnos/usuario/${context.state.user_web.data.id}/disponibles/${context.state.idsetting}`);
	}, [context.state.idsetting, context.state.user_web.data.id]);

	useEffect(() => {
		forceUpdate();
	}, [data, forceUpdate]);

	if(!datos) {
		return <Loader active inline="centered" />
	}

	if(datos.err) {
		return <h1>Error! {datos.msg}</h1>
	}

	return(
		<div className="">
			{
				datos.tieneturno ?
				<>
					<h5>Turno solicitado</h5>
					<hr/>
					{
					 <DetalleTurno data={[datos.detalleturno]} deleteable={true} />
					}
				</>
				:
				<>
					<h5>Solicitar un Turno</h5>
					<hr/>
					<SetTurno data={datos.data} onForce={forceUpdate} />
				</>
			}
		</div>
	);
};

export default Opciones;