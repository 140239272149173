import React, { useContext } from "react";
import { Container, Grid  } from "semantic-ui-react";
import Context from "./../../store/Context";
import Regresar from "./Regresar";

//<Regresar url={`${process.env.PUBLIC_URL}${url}`} reset={reset} resetSetting={resetSetting} />

const HeaderBack = ({url, reset = false, resetSetting = false}) => {
	const context = useContext(Context);

	return(
		<div className="HeaderBack">
			<Container>
				<Grid.Row>
					<Grid.Column width={16}>
						<div className="HeaderBack-body">
							<Regresar url={url} reset={reset} resetSetting={resetSetting} />
							{
								parseInt(context.state.reparticion, 10) === 1 ?
								<svg version="1.1" viewBox="0 0 69.614 39.057" width="100px" xmlns="http://www.w3.org/2000/svg" style={{marginLeft: "1em"}}>
									<g transform="translate(-52.742 -24.662)">
										<g style={{fill: "#ee2737"}} transform="translate(-.89734 .43428)">
											<path d="m98.92 45.434c0-3.8562-3.126-6.9825-6.9822-6.9825-3.8562 0-6.9822 3.1263-6.9822 6.9825h-2.6e-4c0-2.6204-0.86642-5.038-2.328-6.9829 2.123-2.8261 5.5033-4.6546 9.3105-4.6546 3.8068 0 7.1868 1.8281 9.3098 4.6538-1.4616 1.9452-2.3276 4.3632-2.3276 6.9836"/>
											<path d="m122.2 45.434h-4.6549c0-3.8562-3.1263-6.9825-6.9825-6.9825-3.8562 0-6.9825 3.1263-6.9825 6.9825h-4.6552 4.6552c0-2.6204-0.86606-5.0384-2.3276-6.9836 2.1234-2.8257 5.5033-4.6538 9.3102-4.6538 6.4273 0 11.637 5.2102 11.637 11.637"/>
											<path d="m64.007 26.084c-1.065-1.0647-2.7912-1.0647-3.8559 0-1.0647 1.065-1.0647 2.7912 0 3.8559l3.8566 3.8566 0.77082-0.77117 3.0854-3.0854c1.0647-1.0647 1.0647-2.7908 0-3.8559-1.0647-1.0647-2.7912-1.0647-3.8555 0h-1e-3"/>
											<path d="m84.955 45.434h-4.6549c0-3.2311-2.195-5.9485-5.1752-6.7451l1.2047-4.4965c2.5502 0.68156 4.7551 2.207 6.2974 4.2587-1.4612 1.9452-2.3269 4.3628-2.3269 6.9829h4.6549"/>
										</g>
										<g style={{fill: "#cdc4bb"}} transform="translate(-.89734 .43428)">
											<path d="m60.533 60.607h8.4307v1.6192h-8.4307v-1.6192"/>
											<path d="m68.686 58.528 1.7448-2.08c1.0608 0.80962 2.2751 1.1444 3.4198 1.1444 0.58596 0 0.83714-0.15346 0.83714-0.4184v-0.0279c0-0.2794-0.30692-0.43286-1.354-0.64241-2.1915-0.44661-4.1176-1.0746-4.1176-3.1404v-0.0279c0-1.8567 1.452-3.2942 4.1455-3.2942 1.8845 0 3.2805 0.44697 4.4108 1.3402l-1.591 2.2052c-0.92111-0.66992-2.0239-0.96343-2.9312-0.96343-0.4886 0-0.71191 0.16792-0.71191 0.40499v0.0279c0 0.26528 0.26529 0.43286 1.2982 0.62829 2.4984 0.46073 4.1734 1.1868 4.1734 3.1545v0.0279c0 2.0518-1.6887 3.308-4.3127 3.308-1.9823 0-3.7687-0.55809-5.0112-1.6468"/>
											<path d="m85.912 55.136v-0.0282c0-1.1585-0.79586-2.2052-2.0521-2.2052-1.2421 0-2.0239 1.0329-2.0239 2.1774v0.0279c0 1.1589 0.79551 2.2056 2.0518 2.2056 1.2425 0 2.0242-1.0326 2.0242-2.1773zm-7.3702 0v-0.0282c0-2.8332 2.3308-5.0945 5.346-5.0945 3.0148 0 5.3178 2.2334 5.3178 5.0666v0.0279c0 2.8339-2.3308 5.0948-5.3456 5.0948-3.0152 0-5.3181-2.2331-5.3181-5.0666"/>
											<path d="m90.101 50.223h3.4054l2.0937 3.4336 2.0937-3.4336h3.4057v9.7705h-3.2519v-4.8433l-2.2476 3.4752h-0.0557l-2.2472-3.4752v4.8433h-3.1962v-9.7705"/>
											<path d="m109.36 55.136v-0.0282c0-1.1585-0.79586-2.2052-2.0518-2.2052-1.2425 0-2.0242 1.0329-2.0242 2.1774v0.0279c0 1.1589 0.79551 2.2056 2.0521 2.2056 1.2421 0 2.0239-1.0326 2.0239-2.1773zm-7.3702 0v-0.0282c0-2.8332 2.3312-5.0945 5.3464-5.0945 3.0148 0 5.3178 2.2334 5.3178 5.0666v0.0279c0 2.8339-2.3312 5.0948-5.3456 5.0948-3.0152 0-5.3185-2.2331-5.3185-5.0666"/>
											<path d="m112.67 58.528 1.7445-2.08c1.0608 0.80962 2.2751 1.1444 3.4198 1.1444 0.58631 0 0.83749-0.15346 0.83749-0.4184v-0.0279c0-0.2794-0.30727-0.43286-1.354-0.64241-2.1914-0.44661-4.1176-1.0746-4.1176-3.1404v-0.0279c0-1.8567 1.4517-3.2942 4.1455-3.2942 1.8845 0 3.2805 0.44697 4.4108 1.3402l-1.591 2.2052c-0.9211-0.66992-2.0239-0.96343-2.9316-0.96343-0.48824 0-0.71155 0.16792-0.71155 0.40499v0.0279c0 0.26528 0.26529 0.43286 1.2979 0.62829 2.4984 0.46073 4.1737 1.1868 4.1737 3.1545v0.0279c0 2.0518-1.6891 3.308-4.3131 3.308-1.9819 0-3.7687-0.55809-5.0109-1.6468"/>
										</g>
										<g style={{fill: "#ffb81c"}} transform="translate(-.89734 .43428)">
											<path d="m66.335 45.434c0-2.6204-0.86572-5.0384-2.3273-6.9836 2.123-2.8257 5.5033-4.6538 9.3098-4.6538v4.6549c-3.8562 0-6.9825 3.1263-6.9825 6.9825"/>
											<path d="m66.335 45.434h-4.6549c0-3.8562-3.1263-6.9825-6.9825-6.9825v-4.6549c3.8068 0 7.1868 1.8281 9.3102 4.6538-1.4616 1.9452-2.3276 4.3632-2.3276 6.9836h4.6549"/>
										</g>
										<path style={{fill: "#ffb81c"}} d="m65.438 45.868h-4.6549c0-2.6204 0.86606-5.0384 2.3276-6.9836 1.4616 1.9452 2.3273 4.3632 2.3273 6.9836"/>
										<g style={{fill: "#ee2737"}} transform="translate(-.89734 .43428)">
											<path d="m103.58 45.434h-4.6552c0-2.6204 0.86607-5.0384 2.3276-6.9836 1.4616 1.9452 2.3276 4.3632 2.3276 6.9836"/>
											<path d="m64.779 26.855c0.63888-0.63888 1.6746-0.63888 2.3135 0 0.63888 0.63888 0.63888 1.675 0 2.3135l3.5e-4 3.5e-4 -3.0854 3.0854-3.0854-3.0854v-3.4e-4c-0.63888-0.63853-0.63888-1.6746 0-2.3135 0.63888-0.63888 1.6746-0.63888 2.3132 0h3.5e-4l0.77117 0.77117 0.77082-0.77117h1e-3"/>
											<path d="m84.955 45.434h-4.6549c0-2.6201 0.86571-5.0377 2.3269-6.9829 1.4616 1.9449 2.328 4.3624 2.328 6.9829"/>
										</g>
									</g>
								</svg>
								:
								<svg version="1.1" viewBox="0 0 170.1 54.7" width="140px" xmlns="http://www.w3.org/2000/svg" style={{marginLeft: "1em"}}>
									<g transform="translate(-17.379 -14.918)">
										<g style={{fill: "#045db5"}} transform="translate(2.2679 -96.006)">
											<path transform="scale(.26458)" d="m136.62 424.24a74.51 76.025 0 0 0-74.51 76.025 74.51 76.025 0 0 0 74.51 76.025 74.51 76.025 0 0 0 74.51-76.025 74.51 76.025 0 0 0-74.51-76.025zm0.25195 30.064a45.962 46.214 0 0 1 45.963 46.213 45.962 46.214 0 0 1-45.963 46.215 45.962 46.214 0 0 1-45.961-46.215 45.962 46.214 0 0 1 45.961-46.213z"/>
											<path d="m68.287 152.27v-8.0181c3.6169-0.29437 6.9386-1.8177 9.0872-4.41 2.386-2.8786 2.4516-7.107 2.94-10.824 1.3673-5.9426 3.7789-9.8693 7.7508-12.963 3.3023-2.5718 8.0181-4.0107 12.027-3.6081v7.6172c-4.1663 0.5769-7.7662 2.06-10.156 5.479-2.3365 3.037-1.6921 7.1375-2.0045 9.7553-0.42163 3.5331-2.0725 7.1666-4.5436 9.889-3.9779 4.6833-9.3554 6.2876-15.101 7.0826z"/>
										</g>
										<g style={{fill: "#5fc6e9"}} transform="translate(2.2679 -96.006)">
											<rect x="35.948" y="156.15" width="32.072" height="8.1517"/>
											<path transform="scale(.26458)" d="m256.85 424.38s-1.7682 2.427-4.3126 5.2288c-1.6854 1.8559-3.5765 3.9656-5.1484 5.4646-3.2338 3.084-5.6054 7.465-5.6094 12.176 6e-5 8.7339 6.9203 15.814 15.457 15.814 8.5367-2.2e-4 15.457-7.0805 15.457-15.814-7e-3 -4.9837-2.9967-9.2286-6.2148-12.652 0 0-3.274-3.1008-4.8659-4.7638-2.7124-2.8337-4.763-5.453-4.763-5.453z"/>
										</g>
										<path style={{fill: "#cdc4bb"}} d="m105.55 24.857 2.0789-7.654c3.1 1.266 5.8082 1.413 10.583 7.465 5.9568-5.9871 10.534-7.6393 14.741-8.1265 9.1171-0.65328 12.621 3.8772 17.387 8.032 2.736-3.5985 8.9751-7.8071 15.686-8.1265 5.4655-0.26013 11.182 2.8096 14.951 6.7757 3.2479 3.4174 4.4249 8.3268 5.176 13.163l-7.843 0.0945c-1.6151-9.5104-6.2522-11.504-11.812-11.812-6.3146-0.34947-11.952 4.9275-12.379 11.717h-7.843c-0.29362-6.3323-5.9552-11.654-11.528-12.095-7.1255-0.56353-12.469 6.4384-12.473 11.906h-8.032c-0.97573-7.8958-5.0646-10.111-8.6934-11.339z"/>
									</g>
								</svg>
							}
						</div>
					</Grid.Column>
				</Grid.Row>
			</Container>
		</div>
	);
};

export default HeaderBack;