import React, { useContext, useState, useEffect  } from "react";
import Context from "./../../../store/Context";
//import { SET_UPDATES } from "./../../store/constants";
import api from "./../../../utils/api";
import swal from "@sweetalert/with-react";
import { Form } from "semantic-ui-react";

const SetTurno = ({data, onForce}) => {
	const context = useContext(Context);
	const [procesando, setProcesando] = useState(false);
	const [fechasOpt, setFechasOpt] = useState([]);
	const [fechaSelected, setFechaSelected] = useState(null);
	const [turnosOpt, setTurnosOpt] = useState([]);
	const [turnoSelected, setTurnoSelected] = useState(null);

	useEffect(() => {
		if(data && (data.length > 0)) {
			let opciones = data.map((fecha) => {
				return { key: `f-${fecha.fecha}`, text: `${fecha.fecha.split("-").reverse().join("/")} - ${fecha.nombre_dia}`, value: fecha.fecha }
			});
			setFechasOpt(opciones);
			setFechaSelected(opciones[0].value);
			opciones = data[0].horarios.filter((horario) => {
				return horario.disponible;
			});
			opciones = opciones.map((horario, index) => {
				return { key: `t-${index}`, text: `${horario.hora} - Turno ${horario.box}`, value: `${horario.hora}-${horario.box}` }
			});
			setTurnosOpt(opciones);
			setTurnoSelected(opciones[0].value);
		}
	}, [data]);

	function handleFormSubmit() {
		setProcesando(true);
		let hora_box = turnoSelected.split("-");
		let datos = {
			idsetting: parseInt(context.state.idsetting, 10),
			idusuario: parseInt(context.state.user_web.data.id, 10),
			fecha: fechaSelected,
			hora: hora_box[0],
			box: parseInt(hora_box[1], 10)
		};
		api.post("turno/usuario", datos)
		.then(() => {
			onForce();
			//context.dispatch({ type: SET_UPDATES });
			//context.dispatch({ type: SET_SETTING, payload: 0 });
		})
		.catch((error) => {
			if (error.response) {
				swal(
					<div>
						<h1>Oops!</h1>
						<p>Ocurrió un error.</p>
						<ul>
							{error.response.data.errors.map((error, index) => {
								return <li key={`e-${index}`}>{error}</li>;
							})}
						</ul>
					</div>,
					{
						icon: "error"
					}
				);
			} else {
				swal(
					<div>
						<h1>Oops!</h1>
						<p>Ocurrió un error.</p>
						<p>{error.message}</p>
					</div>,
					{
						icon: "error"
					}
				);
			}
			setProcesando(false);
		});
	};

	const handleSelectChange = (event, valor) => {
		let field = valor.name;
		if(field === "fecha") {
			setFechaSelected(valor.value);
			let opciones = data.filter((value) => {
				return value.fecha === valor.value;
			});
			opciones = opciones[0].horarios.filter((horario) => {
				return horario.disponible;
			});
			opciones = opciones.map((horario, index) => {
				return { key: `t-${index}`, text: `${horario.hora} - Turno ${horario.box}`, value: `${horario.hora}-${horario.box}` }
			});
			setTurnosOpt(opciones);
			setTurnoSelected(opciones[0].value);
		} else {
			setTurnoSelected(valor.value);
		}
	};

	return(
		<div className="SetTurno">
			<Form onSubmit={handleFormSubmit} loading={procesando}>
				<Form.Group widths="equal">
					<Form.Select fluid options={fechasOpt} placeholder="Seleccione Fecha" name="fecha" id="fecha" value={fechaSelected} onChange={handleSelectChange} />
					<Form.Select fluid options={turnosOpt} placeholder="Seleccione Turno" name="turno" id="turno" value={turnoSelected} onChange={handleSelectChange} />
					<Form.Button>Aceptar</Form.Button>
				</Form.Group>
			</Form>
		</div>
	);
};

export default SetTurno;