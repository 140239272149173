import React, { useContext, useEffect } from "react";
import Context from "./store/Context";

import Nav from "./components/Nav";
import Contributor from "./components/Contributor";
import Cards from "./components/Cards";
import Infos from "./components/Infos";
import Noticias from "./components/Noticias";
import Contacto from "./components/Contacto";
import Footer from "./components/Footer";

const Home = () => {
	const context = useContext(Context);

	useEffect(() => {
		let root = document.documentElement;
		if(parseInt(context.state.reparticion, 10) === 1) {
			root.style.setProperty("--institucional1", "#ee2737");
			root.style.setProperty("--institucional2", "#ffb81c");
		} else {
			root.style.setProperty("--institucional1", "#045db5");
			root.style.setProperty("--institucional2", "#5fc6e9");
		}
	}, [context.state.reparticion]);

	return(
		<div className="Home" style={{position: "relative"}}>
			<Nav />
			{
				context.state.reparticion === 32 ?
				<Contributor />
				:
				""
			}
			<Cards />
			<Infos />
			<Noticias />
			<Contacto />
			<Footer />
		</div>
	);
};

export default Home;