import React, { useContext } from "react";
import { SWRConfig } from "swr";
import Context from "./store/Context";
import "semantic-ui-css/semantic.min.css";
import "./App.scss";

import Layout from "./Layout";

/*
Para cambiar en package.json
"homepage": "http://192.168.100.2/virtual";
*/

const fetcherContext = (context) => {
	return async (url, init = null) => {
		let default_Init = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": context.state.user_admin.token
			}
		};
		if(init) { default_Init = init; }
		try {
			let response = await fetch(`${process.env.REACT_APP_API_PATH}${url}`, default_Init);
			response = await response.json();
			return response;
		} catch(error) {
			console.error(error, url);
			return null;
		}
	};
};

const App = () => {
	const context = useContext(Context);

	return(
		<SWRConfig value={{
			refreshInterval: 0,
			fetcher: fetcherContext(context)
		}}>
			<Layout />
		</SWRConfig>
	);
};

export default App;